<template>
  <div>
    <vs-sidebar
      v-model="isSidebarActive"
      default-index="1"
      :parent="parent"
      :hidden-background="doNotClose"
      color="primary"
      class="left-sidebar"
      spacer
      :click-not-close="doNotClose"
      :reduce="isSidebarReduced"
    >
      <!-- Header -->
      <div
        slot="header"
        class="header-sidebar text-center d-flex h-100 justify-content-center align-items-center py-0"
      >
        <div>
          <img
            :src="imgpath()"
            :width="logoWidth"
            class="pb-2"
            alt="escudo"
          >
        </div>
      </div>

      <!-- Main -->
      <div
        v-bar
        class="col-sm-12 col-md-12 ml-2 p-1 vb vs-scrollable"
      >
        <div class="row w-100 d-flex m-2 align-items-start">
          <div class="col-sm-12 col-md-12 d-flex justify-content-start align-items-start">
            <div class="row w-100 no-gutters d-flex justify-content-start">
              <div class="col-sm-12 col-md-12 form-group font-weight-bold cursor-pointer">
                <a @click="breadcrumbClick('home')">
                  <vs-icon
                    icon="home"
                    size="medium"
                    color="black"
                  />

                  <strong>/</strong>
                </a>

                <label
                  v-if="menuBreadcrumb"
                  class="ml-2 mb-0"
                >
                  {{ menuBreadcrumb }}
                </label>

                <label
                  v-else
                  class="ml-2 mb-0"
                >
                  HOME
                </label>

                <img
                  v-if="menuBreadcrumb"
                  class="mt-1 font-weight-light dark back-router"
                  src="/static/assets/imgs/dashboard/back-icon.png"
                  width="23px"
                  alt="VOLTAR"
                  @click="breadcrumbClick('voltar')"
                >
              </div>
            </div>
          </div>

          <div
            v-if="$store.state.loginData.accessValues.length === 0"
            class="pr-5"
          >
            <p>
              Não há acessos atribuídos à sua conta. <br>
              Entre em contato com o adminitrador.
            </p>
          </div>

          <div
            v-for="(item, i) in menuItems"
            :key="i"
          >
            <div
              v-if="checkItemAccess(item.accessValue)"
              style="max-width: 150px; min-width: 150px"
            >
              <div class="col-sm-12 col-md-7 row ml-1 d-flex justify-content-center align-items-center mt-2">
                <div
                  class="col-sm-12 col-md-12 iconsFix d-flex justify-content-center align-items-center shadow-sm"
                  style="cursor: pointer; border-color: white"
                  :style="[
                    isCardActive(item)
                      ? { backgroundColor: colorSecondary }
                      : { backgroundColor: item.nomeSubCat == 'SINGLE FAN VIEW' ? '#615D5D' : btnColor },
                  ]"
                  @click="clickItem(item)"
                >
                  <span v-if="item.icon.includes('fa')">
                    <i
                      :class="item.icon"
                      style="font-size: 22px"
                    />
                  </span>

                  <span v-else>
                    <InlineSvg
                      :src="item.icon.split(' ')[0]"
                      width="30"
                      height="30"
                      :fill="item.icon.split(' ')[1]"
                    />
                  </span>
                </div>

                <div class="col-sm-12 col-md-12 text-center mt-1">
                  <label
                    style="font-size: 0.66rem;"
                    class="small font-weight-bold"
                    :style="{ color: item.colorFont }"
                  >
                    {{ item.categoria ? item.categoria.replaceAll("&CLUBE&", client).replaceAll("&GENDER&", gender == 1 ? 'A ' : 'O ') : item.nomeSubCat.replaceAll('&CLUBE&', client).replaceAll("&GENDER&", gender == 1 ? 'A ' : 'O ') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div
        slot="footer"
        class="footer-sidebar"
        :style="{ backgroundColor: bgColor }"
      >
        <vs-button
          v-if="checkItemAccess(['Filtros']) && ownerId != 0"
          class="btn--white-on-hover"
          style="border-radius: 0px"
          icon="filter_list"
          color="secondary"
          type="filled"
          :text-color="buttonTextColor"
          @click="goToFilters"
        >
          <span class="hide-in-minisidebar">
            Filtros
          </span>
        </vs-button>

        <vs-button
          style="border-radius: 0px"
          icon="reply"
          color="dark"
          type="filled"
          @click="logout"
        >
          <span class="hide-in-minisidebar">
            Sair
          </span>
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
/* Components */
import InlineSvg from 'vue-inline-svg';

/* Config */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

/* Helpers */
import getTextContrast from '@/helpers/get-text-contrast';

/* Services */
import User from '@/services/user';

import axios from 'axios';

const userService = new User();

/* State */
import { mapState } from 'vuex';

export default {
  name: 'SideBar',
  components: {
    InlineSvg,
  },
  props: {
    parent: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    doNotClose: false,
    windowWidth: window.innerWidth,
    bgColor: '#2E2E2D'
  }),
  computed: {
    buttonTextColor() {
      return this.getTextContrast(this.colorSecondary) === 'light' ? 'white' : this.colorPrimary;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');

      return token[0].replace('tk=', '');
    },
    gender() {
      return this.$store.state.configBox.nouns.gender
    },
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    client() {
      let nameNouns = this.$store.state.configBox.nouns.client

      if(nameNouns == null || nameNouns == undefined || nameNouns == '')
        return 'CLUBE';

      return nameNouns.toUpperCase();
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    logoWidth() {
      return this.$store.state.configBox.logoWidth;
    },
    menuItems() {
      return this.menuSidebar[this.menuSidebar.length - 1];
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    btnColor(){
      return this.$store.state.configBox.bgCardColors;
    },
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit('IS_SIDEBAR_ACTIVE', val);
      },
      themeColor: {
        get() {
          return this.$store.state.themeColor;
        },
        set(val) {
          this.$store.commit('SET_THEME_COLOR', val);
        },
      },
    },
    isSidebarReduced: {
      get() {
        return this.$store.state.isSidebarReduced;
      },
      set(val) {
        this.$store.commit('TOGGLE_REDUCE_SIDEBAR', val);
      },
    },
    ...mapState(['menuBreadcrumb', 'menuSidebar', 'menuSelectItem']),
  },
  watch: {
    $route() {
      if (this.windowWidth < 1170)
        this.$store.commit('IS_SIDEBAR_ACTIVE', false);
    },
    '$store.state.configBox'() {
      this.configSideBar();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });

    this.setSidebarWidth();

    this.configSideBar();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);

    this.setSidebarWidth();
  },
  methods: {
    checkItemAccess(access) {
      if (!access) {
        return true;
      } else {
        let resp = false;

        access.forEach((i) => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            resp = true;
          }
        });

        return resp;
      }
    },
    logout() {
      userService
        .logout(this.$store.state.accessToken.replace('tk=', ''))
        .then(() => {
          this.$store.dispatch('remove_accessToken');
          window.location = this.$store.state.logoutUrl;
        })
        .catch(() => {
          this.$vs.notify({
            color: 'rgb(255, 130, 0)',
            title: 'Erro de conexão',
            text: 'Verifique sua rede e tente novamente.',
          });
        });
    },
    imgpath() {
      return `${configDomain}/sys/101/${this.ownerId}/logo.png`;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;

      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1170) {
        this.$store.commit('IS_SIDEBAR_ACTIVE', false);
        (this.doNotClose = false),
        this.$store.dispatch('updateSidebarWidth', 'no-sidebar');
      } else {
        this.$store.commit('IS_SIDEBAR_ACTIVE', true);
        this.doNotClose = true;

        if (this.isSidebarReduced) {
          this.$store.dispatch('updateSidebarWidth', 'mini');
        } else {
          this.$store.dispatch('updateSidebarWidth', 'default');
        }
      }
    },
    isCardActive(item) {
      return this.menuSelectItem[this.menuSelectItem.length - 1] === item;
    },
    configSideBar() {
      var sidebar = document.querySelector('.vs-sidebar--header');

      sidebar.setAttribute(
        'style',
        'background-color:' + this.$store.state.configBox.cor1
      );

      sidebar.classList.add('bgConfig');
    },
    breadcrumbClick(target) {
      if (target === 'home') {
        this.$store.dispatch('splice_menuSidebar', 1);
        this.$store.dispatch('splice_menuSelectItem', 0);
        this.$store.commit('SET_boxTitle', '');
        this.$router.push({ path: '/HomeUser' });
      } else if (target === 'voltar') {
        this.$store.dispatch('pop_menuSidebar');
        this.$store.dispatch('pop_menuSelectItem');

        if (this.menuSidebar.length === 1) {
          this.$router.push({ path: '/HomeUser' });
        } else {
          this.clickItem(this.menuSelectItem[this.menuSelectItem.length - 1]);
        }
      }

      if (this.menuSidebar.length === 1) {
        this.$store.dispatch('set_menuBreadcrumb', '');
      }
    },
    /* Helpers */
    getTextContrast(hex) {
      return getTextContrast(hex);
    },
    /* Router */
    goToFilters() {
      // clean menu breadcrumb
      this.$store.dispatch('splice_menuSidebar', 1);
      this.$store.dispatch('splice_menuSelectItem', 0);
      this.$store.commit('SET_boxTitle', '');

      // set a menuSelectItem to show the page's name on breadcrumb
      this.$store.dispatch('push_menuSelectItem', { categoria: 'FILTROS' });
      this.$router.push('/IDDigital/filtros');
    },
    clickItem(item) {
      if (item !== this.menuSelectItem[this.menuSelectItem.length - 1]) {
        this.$store.dispatch('splice_menuSelectItem', item);
      }

      let clicado = '';

      if (item.categoria) {
        this.$store.dispatch('set_menuBreadcrumb', item.categoria);
        this.$store.commit('SET_boxTitle', item.categoria);
        clicado = item.categoria;
      } else if (item.nomeSubCat) {
        this.$store.commit('SET_boxTitle', item.nomeSubCat);
        clicado = item.nomeSubCat
      }

      if (item.SubCategorias && item.urlRedirect !== '/interval') {
        this.$store.dispatch('push_menuSidebar', item.SubCategorias);
      }

      const baseDomain = process.env.VUE_APP_BASEDOMAIN;
      const baseEnv = process.env.VUE_APP_BASEENV;
      const baseUrl = `${baseDomain}/${baseEnv}`;

      let url = `${baseUrl}/NavigationLog/create/${clicado}`

      const headers = { headers: { Authorization: `Bearer ${this.token}` } };

      axios
        .post(url, null, headers)
        .then();

      this.$router.push({ path: item.urlRedirect });
    },
  },
};
</script>

<style>
.iconsFix {
  background-color: white;
  height: 85px;
  border-radius: 18px;
  min-width: 85px;
  max-width: 85px;
  background-color: white;
}

.iconsFix:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
  background-color: #c9cccb;
}

.iconsFix:hover {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
  background-color: #c9cccb;
}

.vs-sidebar {
  max-width: 335px;
}

.main-wrapper-default .main-container-fluid {
  margin-left: 335px;
}

.vs-sidebar--items {
  background-color: #f8f8f8;
}

.vs-spacer {
  background-color: #f8f8f8;
}

.iconSidebar {
  font-size: 90%;
}

.footer-sidebar .vs-button {
  width: 100%;
}

.footer-sidebar > .vs-button:hover {
  background-color: #40403f !important;
}

.bgConfig {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 186px !important;
  max-height: 186px !important;
  padding: 0px !important;
}

.back-router{
  position: absolute;
  left: 268px;
  top: 1px;
}

.back-router:hover{
  transform: scale(1.03);
  transition: 0.2s;
  cursor: pointer;
}

.btn--white-on-hover:hover {
  color: white !important;
}
</style>
